// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_auth.login/route.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_auth.login/route.tsx");
  import.meta.hot.lastModified = "1730486026878.4314";
}
// REMIX HMR END

import { Form, useLoaderData } from "@remix-run/react";
import { json } from "@remix-run/node";
import authenticator from "~/services/oauth.server.ts";
import { sessionStorage } from "~/services/session.server.ts";
import { GeneralErrorBoundary } from "~/components/error-boundary";
/**
 * get the cookie and see if there are any errors that were
 * generated when attempting to login
 *
 */
export const loader = async ({
  request
}) => {
  await authenticator.isAuthenticated(request, {
    successRedirect: "/facilities"
  });
  const session = await sessionStorage.getSession(request.headers.get("Cookie"));
  const error = session.get(authenticator.sessionErrorKey);
  return json({
    error
  });
};
export const action = async ({
  request,
  context
}) => {
  return await authenticator.authenticate("nexus-oauth", request, {
    successRedirect: "/facilities",
    failureRedirect: "/login"
    // throwOnError: true,
    // context,
  });
};
const styles = {
  wrapper: 'flex flex-col h-screen',
  wrapperInnerTop: '',
  wrapperInnerBottom: 'h-1/2 bg-[#05afef] pt-8',
  logo: 'w-full',
  form: 'rounded px-8 pt-6 pb-8 mb-4',
  formItem: 'mb-4',
  input: 'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
  button: 'bg-white hover:bg-gray-100 py-2 px-4 w-full rounded focus:outline-none focus:shadow-outline',
  link: 'inline-block align-baseline font-bold text-sm text-white',
  error: 'text-xl text-red-500 font-bold text-center'
};
export default function LoginPage() {
  _s();
  const loaderData = useLoaderData();

  //TODO reset password missing
  return <div className={styles.wrapper}>
      <div className="p-4 h-1/2 flex justify-center content-center">
        <img className=" w-full max-w-2xl" src="/images/nexus-logo.svg" />
      </div>
      <div className={styles.wrapperInnerBottom}>
        <div className="mx-auto max-w-2xl	">
          <Form className={styles.form} method="post">
            <div className={styles.formItem}>
              <button className={styles.button}>
                Sign In
              </button>

            </div>
            {/* <div className="text-center">
              <a className={ styles.link } href="#">
                Forgot Password / Change Password
              </a>
             </div> */}
          </Form>
          <div className={styles.error}>
            {loaderData?.error ? <p>ERROR: {loaderData?.error?.message}</p> : null}
          </div>
        </div>
      </div>
    </div>;
}
_s(LoginPage, "ceKF1Gd7W4lGV+M78eBsU+KQIkw=", false, function () {
  return [useLoaderData];
});
_c = LoginPage;
export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
_c2 = ErrorBoundary;
var _c, _c2;
$RefreshReg$(_c, "LoginPage");
$RefreshReg$(_c2, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;